import '@fontsource/poppins/400.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/500-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/600-italic.css';
import './src/styles/global.css';

// Lazy load intersection observer polyfill
export async function onClientEntry() {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer');
    // eslint-disable-next-line no-console
    console.log('Loaded IntersectionObserver polyfill');
  }
}
